@import 'styles/colors.scss';

.items {
  display: flex;
  flex-direction: column;

  margin-bottom: 15px;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 8px;
    margin-bottom: 8px;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .label {
      font-size: 15px;
      font-weight: 600;

      color: #ffffff;

      width: 100px;
    }

    input,
    select {
      flex: 1;

      appearance: none;
      border: none;

      border-radius: 5px;

      margin: 0px;
      padding: 5px 10px;

      font-size: 15px;

      background-color: $bg-secondary;
      color: #ffffff;
    }
  }
}
