@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.dialog {
  display: flex;
  flex-direction: column;

  padding: 16px;
  border-radius: 12px;

  background-color: $bg-primary;

  margin-left: 16px;
  margin-right: 16px;

  .title {
    pointer-events: none;

    font-size: 20px;
    color: #ffffff;

    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .description {
    pointer-events: none;

    font-size: 15px;
    color: #ffffff80;

    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .buttons {
    pointer-events: auto;

    display: flex;
    flex-direction: row;
    align-items: center;

    .button {
      flex: 1;

      padding: 8px 12px;

      font-size: 15px;
      color: #ffffff;

      border-radius: 8px;

      transition: background-color 150ms;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &.primary {
        background-color: $button-secondary-bg;

        &:hover {
          background-color: $button-secondary-bg-selected;
        }
      }

      &.secondary {
        background-color: $button-primary-bg;

        &:hover {
          background-color: $button-primary-bg-selected;
        }
      }

      @include pointer;

      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
