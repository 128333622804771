@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.toolbarButton {
  @include pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 35px;

  padding-left: 10px;
  padding-right: 10px;

  border-radius: 8px;

  background-color: $button-secondary-bg;
  color: #ffffffb0;

  transition: background-color 150ms, color 150ms;

  .icon {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;

      fill: #ffffffb0;
      transition: fill 150ms;
    }
  }

  &:hover,
  &.selected {
    background-color: $button-secondary-bg-selected;
    color: #ffffff;

    .icon {
      svg {
        fill: #ffffff;
      }
    }
  }
}
