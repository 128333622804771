@import 'styles/colors.scss';

.statusBar {
  height: calc(35px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  background-color: $status-bar-bg;

  .item {
    margin-right: 15px;

    font-size: 13px;
    font-weight: 600;

    color: #ffffff;
  }
}
