@import 'styles/mixins.scss';

.popupMenu {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  overflow: hidden;

  height: 35px;
  border-radius: 8px;

  background-color: #000000;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 12px;
    padding-right: 12px;

    border-right: 1px solid #252525;

    &:last-child {
      border-right: none;
    }

    color: #ffffff;
    transition: background-color 100ms;

    font-size: 14px;

    @include pointer;

    &.destructive {
      color: #ff5050;
    }

    &:hover {
      background-color: #101010;
    }
  }
}
