@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.playbackToolbar {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 45px;

  padding-left: 8px;
  padding-right: 8px;

  background-color: $playback-controls-bg;

  .button {
    width: 24px;
    height: 24px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include pointer;

    svg {
      width: 14px;
      height: 14px;

      transition: fill 150ms;

      fill: #ffffff;
    }

    &:hover {
      svg {
        fill: #ffffffdd;
      }
    }
  }

  .time {
    margin-left: 8px;

    font-size: 16px;
    font-variant-numeric: tabular-nums;

    &.current {
      color: #b2ebf2;
    }

    &.total {
      color: #bcd6ff;
    }
  }

  .bars {
    margin-left: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 16px;
    font-variant-numeric: tabular-nums;

    color: #62e8ff;
  }

  .spacer {
    flex-grow: 1;
  }
}
