@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.toolbarRadioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;

  .item {
    @include pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 35px;

    padding-left: 10px;
    padding-right: 10px;

    background-color: $toolbar-bg;
    border-radius: 8px;

    color: #ffffffb0;

    .icon {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;

        fill: #ffffffb0;
      }
    }

    &.selected {
      background-color: $toolbar-bg-selected;
      color: #ffffff;

      .icon {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
