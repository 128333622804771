@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.tracksEditorToolbar {
  height: 50px;

  background-color: $toolbar-bg;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 8px;
  padding-right: 8px;

  overflow-x: auto;

  & > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
