.modal {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 50;

  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .barrier {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #00000060;

    pointer-events: auto;
  }
}
