@import 'styles/colors.scss';

.trackTimeline {
  position: relative;

  height: 30px;
  background-color: $tracks-editor-timeline-bg;

  border-bottom: 1px solid $divider;

  .gridlines {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;

    pointer-events: none;
  }

  .labels {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 2;

    pointer-events: none;

    .label {
      position: absolute;

      top: 3px;
      transform: translateX(-50%);

      font-size: 9px;
      font-weight: bold;

      color: #ffffff80;
    }
  }

  .playhead {
    position: absolute;

    height: 6px;
    background-color: $tracks-editor-timeline-playhead-bg;

    left: 0;
    bottom: 0;

    z-index: 1;
  }
}
