@import 'styles/colors.scss';

.container {
  margin-bottom: 16px;

  .input {
    appearance: none;
    color: #ffffff;

    font-size: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    padding: 10px;

    border-radius: 8px;

    background-color: $bg-secondary;
  }
}
