@import 'styles/colors.scss';

.scrollbar {
  display: flex;
  align-items: stretch;

  &.horizontal {
    flex-direction: row;
    width: 100%;

    .thumb {
      height: 20px;
    }
  }

  &.vertical {
    flex-direction: column;
    height: 100%;

    .thumb {
      width: 20px;
    }
  }

  .thumb {
    touch-action: none;

    background-color: $scroll-bar-thumb-bg;
    border-radius: 5px;

    transition: background-color 150ms;

    &:hover {
      background-color: $scroll-bar-thumb-bg-selected;
    }
  }
}
