@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.page {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: $bg-primary;

  .logo {
    padding: 0 30px;
    width: 640px;
    max-width: 100%;

    path {
      fill: #ffffff;
    }
  }

  .button {
    margin-top: 96px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 200px;
    height: 64px;

    border-radius: 24px;

    color: #ffffff;
    background: linear-gradient(to bottom right, #dd2476, #ff512f);

    font-size: 24px;
    font-weight: bold;

    transition: transform 250ms;

    @include pointer;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }
}
