@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.trackHeader {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  height: 80px;

  background-color: $tracks-editor-header-bg;
  color: #ffffff;

  .handle {
    @include pointer;

    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 12px;

    background-color: $tracks-editor-header-handle-bg;

    svg {
      pointer-events: none;

      width: 4px;
      height: 16px;

      fill: #ffffff40;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 10px;

    .name {
      width: 100%;

      margin: 0;
      padding: 0;

      appearance: none;
      border: none;
      background: none;

      font-size: 16px;
      color: #ffffff;

      &:focus {
        outline: none;
      }
    }

    .instrument {
      margin-top: 2px;

      font-size: 12px;
      font-weight: 500;

      color: #ffffff60;

      @include pointer;
    }

    // .volume {
    //   width: 100%;

    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;

    //   .icon {
    //     @include pointer;

    //     width: 14px;
    //     height: 14px;

    //     path {
    //       fill: #ffffff50;
    //     }
    //   }

    //   .slider {
    //     flex: 1;
    //     margin-left: 8px;
    //   }
    // }
  }

  &.selected {
    background-color: $tracks-editor-header-bg-selected;

    .handle {
      background-color: $tracks-editor-header-handle-bg-selected;

      svg {
        fill: #ffffff80;
      }
    }
  }
}
