@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.instrumentCollectionModal {
  background-color: $bg-primary;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  .head {
    padding: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 20px;
      color: #ffffff;
    }

    .closeBtn {
      @include pointer;

      width: 16px;
      height: 16px;

      path {
        fill: #ffffff30;
      }
    }
  }

  .categories {
    overflow-y: auto;

    flex: 1;
    padding: 16px;

    .category {
      margin-bottom: 48px;

      .name {
        font-weight: bold;
        color: #ffffff;
      }

      .instruments {
        margin-top: 16px;

        .instrument {
          margin-bottom: 12px;
          padding: 16px;

          border: 1px solid $bg-secondary;
          border-radius: 8px;
          background-color: $bg-secondary;

          font-size: 18px;
          color: #ffffff;

          @include pointer;

          transition: background-color 150ms;

          &:hover,
          &.selected {
            background-color: lighten($bg-secondary, 3);
          }

          &.selected {
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }
}
