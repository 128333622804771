@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.contextMenu {
  background-color: $context-menu-bg;

  border: 1px solid $context-menu-bg-selected;
  border-radius: 8px;

  padding: 5px;
  min-width: 200px;

  overflow: hidden;

  .item {
    padding: 10px 10px;

    color: #ffffff;
    font-size: 14px;

    border-radius: 8px;

    background-color: transparent;
    transition: background-color 100ms;

    .title {
      pointer-events: none;
    }

    &:hover {
      background-color: $context-menu-bg-selected;

      @include pointer;
    }

    &.disabled {
      pointer-events: none;
      color: #ffffff60;
    }
  }

  .divider {
    margin: 10px;

    height: 1px;
    background-color: $divider;
  }
}
