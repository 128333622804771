@import 'styles/colors.scss';

.trackItem {
  position: absolute;

  top: 8px;
  bottom: 8px;
  left: 0;

  overflow: hidden;
  touch-action: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;

  transition: background-color 150ms;

  cursor: default;

  .name {
    pointer-events: none;

    color: #ffffff;
    font-size: 20px;
  }

  .octave {
    position: absolute;

    right: 8px;
    bottom: 8px;

    font-size: 20px;
    font-weight: bold;

    color: #ffffff2a;

    pointer-events: none;
  }

  &.selected {
    border: 1px solid #ffffff;

    .octave {
      right: 7px;
      bottom: 7px;
    }
  }

  &.note {
    background-color: $tracks-editor-note-bg;

    &:hover,
    &.selected {
      background-color: $tracks-editor-note-bg-selected;
    }
  }

  &.chord {
    background-color: $tracks-editor-chord-bg;

    &:hover,
    &.selected {
      background-color: $tracks-editor-chord-bg-selected;
    }
  }
}
