@import 'styles/colors.scss';

.page {
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: $bg-primary;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  & > * {
    flex-shrink: 0;
  }

  .tracksEditor {
    flex-grow: 1;

    height: 0px;
    border-bottom: 1px solid $divider;

    position: relative;
  }

  .scrollbar {
    padding-top: 3px;
    padding-left: 3px;
    padding-right: 3px;

    background-color: $status-bar-bg;
  }
}
