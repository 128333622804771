$bg-primary: #1f222b;
$bg-secondary: lighten($bg-primary, 3);

$divider: #ffffff15;

$button-primary-bg: $bg-secondary;
$button-primary-bg-selected: lighten($button-primary-bg, 3);
$button-secondary-bg: lighten($bg-secondary, 3);
$button-secondary-bg-selected: lighten($button-secondary-bg, 3);

$playback-controls-bg: $bg-primary;

$toolbar-bg: $bg-secondary;
$toolbar-bg-selected: lighten($toolbar-bg, 5);

$tracks-editor-bg: $bg-primary;
$tracks-editor-timeline-bg: $bg-secondary;
$tracks-editor-timeline-playhead-bg: #d070fad0;
$tracks-editor-header-bg: $bg-secondary;
$tracks-editor-header-bg-selected: lighten($tracks-editor-header-bg, 3);
$tracks-editor-header-handle-bg: #ffffff10;
$tracks-editor-header-handle-bg-selected: #00d0a040;
$tracks-editor-note-bg: #1d8065;
$tracks-editor-note-bg-selected: lighten($tracks-editor-note-bg, 2);
$tracks-editor-chord-bg: #2476a6;
$tracks-editor-chord-bg-selected: lighten($tracks-editor-chord-bg, 2);

$scroll-bar-thumb-bg: lighten($bg-secondary, 5);
$scroll-bar-thumb-bg-selected: lighten($scroll-bar-thumb-bg, 2);

$status-bar-bg: $bg-secondary;

$context-menu-bg: lighten($bg-secondary, 5);
$context-menu-bg-selected: lighten($context-menu-bg, 5);
