@import 'styles/colors.scss';

.tracksEditor {
  overflow: auto;

  display: flex;
  flex-direction: column;

  background-color: $tracks-editor-bg;

  &::-webkit-scrollbar {
    display: none;
  }

  .timeline {
    flex-shrink: 0;

    position: sticky;
    position: -webkit-sticky;

    top: 0;
    z-index: 5;

    transform: translate3d(0, 0, 0);
  }

  .horizontalBox {
    flex-grow: 1;

    position: absolute;
    top: 30px;
    left: 0;

    display: flex;
    flex-direction: row;

    transform: translate3d(0, 0, 0);

    .trackHeaders {
      flex-shrink: 0;

      position: sticky;
      position: -webkit-sticky;

      left: 0;
      z-index: 4;

      transform: translate3d(0, 0, 0);
    }

    .trackGrids {
      flex-grow: 1;
    }
  }
}
