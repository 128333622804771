@import 'styles/colors.scss';

.trackHeaders {
  position: relative;

  .gridlines {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 2;

    pointer-events: none;
  }

  .headers {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;
  }
}
