.trackCanvas {
  position: relative;

  .gridlines {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;

    pointer-events: none;
  }

  .tracks {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 2;

    touch-action: none;

    .track {
      position: relative;

      cursor: crosshair;
    }
  }
}
